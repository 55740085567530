import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { AuthenticationService } from './services/authentication.service';

@Injectable({ providedIn: 'root'})
export class AuthGuard implements CanActivate{
    constructor(private router: Router, private authservice: AuthenticationService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot){
        if (this.authservice.controlToken) {
          return true;
        }
        this.router.navigate(['/Login'], { queryParams: { returnUrl: state.url} });
        return false;
    }
}