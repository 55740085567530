import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { Storage } from '@ionic/storage';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Response } from '../gorev.model';
import { ToastController } from '@ionic/angular';

const store = new Storage();
store.create();

@Injectable({
  providedIn: 'root'
})

export class AuthenticationService {
  token = '';

  constructor(private http: HttpClient, private toast: ToastController) {  }

  public get controlToken(): Boolean {
    if (window.localStorage.getItem("Token") === null || window.localStorage.getItem("Token") === undefined) {
      window.localStorage.setItem("Token", "NoToken");
      return false;
    }
    var jwt = window.localStorage.getItem("Token");
    try {
      const jwtData = JSON.parse(atob(jwt.split(".")[1]));
      var remain = (jwtData.exp * 1000) - Date.now();
      if (remain > 0) {
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  }

  getTokenData() {
    if (window.localStorage.getItem("Token") === null) {
      return false;
    }
    var jwt = window.localStorage.getItem("Token");
    const jwtData = JSON.parse(atob(jwt.split(".")[1]));
    return jwtData;
  }

  logOut() {
    window.localStorage.clear();
    window.location.reload();
  }

  getToken() {
    if (window.localStorage.getItem("Token") === null || window.localStorage.getItem("Token") === undefined) {
      this.logOut();
      return false;
    }
    return window.localStorage.getItem("Token");
  }

  async returnResponse(res: Response) {
    if (!res.isSuccess) {
      switch (res.code) {
        case 401:
          this.logOut();
          break;
        case 400:
          var a = await this.toast.create({
            color: "danger",
            header: res.errorType,
            message: res.errorMsg,
            duration: 4000,
            mode: "ios",
            animated: true
          });
          a.present();
          break;
        default:
          var a = await this.toast.create({
            color: "danger",
            header: res.errorType,
            message: res.errorMsg,
            duration: 4000,
            mode: "ios",
            animated: true
          });
          a.present();
          break;
      }
    }
    return res;
  }


  login(username, password) {
    const frmData = new FormData;
    frmData.append("username", username);
    frmData.append("password", password);
    return this.http.post<Response>(`${environment.apiUrl}/auth/login`, frmData).pipe(map(async res => {
      console.log(res);
      if (res.isSuccess) {
        localStorage.setItem("Token", res.response.token);
      }
      else {
        if (res.code === 401) {
          var a = await this.toast.create({
            color: "danger",
            header: res.errorType,
            message: res.errorMsg,
            duration: 4000,
            mode: "ios",
            animated: true
          });
          a.present();
        }
        else {
          var a = await this.toast.create({
            color: "danger",
            header: res.errorType,
            message: res.errorMsg,
            duration: 4000,
            mode: "ios",
            animated: true
          });
          a.present();
        }
      }
      return res;
    }));
  }

}
