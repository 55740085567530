import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'message',
    loadChildren: () => import('./new-message/new-message.module').then( m => m.NewMessagePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'messageDetail',
    loadChildren: () => import('./view-message/view-message.module').then( m => m.ViewMessagePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'Login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'new-message',
    loadChildren: () => import('./new-message/new-message.module').then( m => m.NewMessagePageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
